@import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700&display=swap');

.changelog {
  background-color: rgb(60, 60, 60);
  width: 47.5%;
  height: 90%;
  border-radius: 5px;
  box-shadow: 0 0 8px rgb(10, 10, 10);
  overflow: hidden;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
}

.changelog-body {
  font-family: 'Inconsolata', monospace;
  padding: 15px;
  font-size: 20px;
  overflow: scroll;
}

.version {
  margin-top: 20px;
  flex-shrink: 0;
}

.changes {
  margin-left: 20px;
  flex-shrink: 0;
}

@media (max-width: 920px) {
  .changelog {
    width: 95%;
    height: 90%;
  }
}
