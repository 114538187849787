@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:500,700&display=swap');

.home {
  width: 47.5%;
  height: 70%;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.typewriter {
  font-family: 'Source Code Pro', monospace;
  font-size: 30px;
}

.caret {
  border-right: 2px solid white;
  font-size: 30px;
  width: 2px;
}

.aboutme {
  background-color: rgb(35, 39, 49);
  box-shadow: 0 0 8px rgb(10, 10, 10);
  border-radius: 5px;
  overflow: hidden;
}

.aboutme-body {
  font-family: 'Source Code Pro', monospace;
  text-align: start;
  padding: 18px;
}

.aboutme-body > div {
  color: rgb(240, 240, 240);
}

@media (max-width: 920px) {
  .home {
    width: 95%;
    justify-content: space-around;
  }

  .typewriter-cont {
    height: 60px;
    flex-shrink: 0;
  }

  .typewriter {
    font-size: 20px;
  }

  .caret {
    font-size: 20px;
  }
}
