@import url(https://fonts.googleapis.com/css?family=Inconsolata:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inconsolata:400,700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: rgb(34 39 50);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




.App {
  position: relative;
  width: 90vw;
  height: 100vh;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 920px)  {
  .App {
    width: 100vw;
    height: 100vh;
    
    flex-direction: column;
  }
}

@media (max-width: 360px)  {
  * {
    font-size: 15px;
  }

  .App {
    width: 100vw;
    height: 100vh;
    
    flex-direction: column;
  }
}

.term {
  background-color: rgb(30, 30, 30);
  width: 47.5%;
  height: 90%;
  border-radius: 5px;
  box-shadow: 0 0 8px rgb(10, 10, 10);
  position: relative;
  overflow: hidden;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
}

.term:hover {
  cursor: text;
}

.topbar {
  width: 100%;
  height: 30px;
  background-color: rgb(43, 45, 47);
  padding: 0 10px 0 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.topbar:hover {
  cursor: default;
}

.topbar-buttons {
  width: 65px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.topbar-button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.exit {
  background-color: rgb(238, 101, 90);
}

.minimize {
  background-color: rgb(225, 192, 76);
}

.maximize {
  background-color: rgb(114, 190, 71);
}

.mac-symbols {
  color: rgb(100, 100, 100);
}

.instructions {
  padding: 15px 10px 0 10px;
  font-size: 20px;
  font-family: 'Inconsolata', monospace;

  flex-shrink: 0;
}

.links {
  padding: 10px 10px 10px 10px;
  font-size: 20px;
  font-family: 'Inconsolata', monospace;

  flex-shrink: 0;
}

.link {
  color: rgb(0, 255, 255);
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
}

.cli {
  position: relative;
  padding: 5px 10px 0 10px;
  font-size: 20px;
  font-family: 'Inconsolata', monospace;
  overflow: scroll;
}

.cli-location {
  color: pink;
  font-weight: bold;
}

.cli-input {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: red;
  font-weight: bold;
}

.cli-text {
  background-color: rgb(30, 30, 30);
  font-size: 20px;
  font-family: 'Inconsolata', monospace;
  width: 95%;
  border: 0px;

  outline: 0;
  resize: none;
}

.term-socials {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.github-term, .linkedin-term, .angelist-term {
  margin: 5px;
  height: 25px;
  width: 25px;
  fill: #555;
}

.github-term:hover, .linkedin-term:hover, .angelist-term:hover {
  fill: white;
}

@media (max-width: 920px) {
  .term {
    margin: 10px 0px;
    width: 95%;
    height: 40vh;

    flex-shrink: 0;
  }
}

.home {
  width: 47.5%;
  height: 70%;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.typewriter {
  font-family: 'Source Code Pro', monospace;
  font-size: 30px;
}

.caret {
  border-right: 2px solid white;
  font-size: 30px;
  width: 2px;
}

.aboutme {
  background-color: rgb(35, 39, 49);
  box-shadow: 0 0 8px rgb(10, 10, 10);
  border-radius: 5px;
  overflow: hidden;
}

.aboutme-body {
  font-family: 'Source Code Pro', monospace;
  text-align: start;
  padding: 18px;
}

.aboutme-body > div {
  color: rgb(240, 240, 240);
}

@media (max-width: 920px) {
  .home {
    width: 95%;
    justify-content: space-around;
  }

  .typewriter-cont {
    height: 60px;
    flex-shrink: 0;
  }

  .typewriter {
    font-size: 20px;
  }

  .caret {
    font-size: 20px;
  }
}

.projects {
  position: relative;
  width: 47.5%;
  height: 90%;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.project-item {
  border-radius: 5px;
  position: relative;
  background-color: rgb(100, 100, 100);
  box-shadow: 0 0 8px rgb(10, 10, 10);
  width: 95%;
  min-height: 45%;
  margin-top: 5px;
  margin-bottom: 20px;
  overflow: hidden;

  flex-shrink: 0;
}

.project-item:last-child {
  margin-bottom: 5px;
}

.project-item-body {
  padding: 10px 10px;
  font-size: 17px;
  line-height: 25px;
  font-family: 'Source Code Pro', monospace;
}

.project-item-body img {
  width: 100%;
  height: auto;
}

.project-item-table > tbody > tr > td {
  vertical-align: top;
  padding: 5px;
}

.project-item-info a {
  text-decoration: none;
  color: rgb(0, 255, 255);
}

h1 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 920px) {
  .projects {
    width: 95%;
  }

  .project-item-body {
    line-height: 17px;
    font-size: 12px;
  }
}


.skills {
  background-color: rgb(20, 30, 20);
  width: 47.5%;
  height: 90%;
  border-radius: 5px;
  box-shadow: 0 0 8px rgb(10, 10, 10);
  overflow: hidden;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
}

.skills-body {
  font-size: 20px;
  padding: 15px 10px;
  overflow: scroll;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

svg {
  height: 80px;
  width: 80px;
  margin: 15px;
}

@media (max-width: 920px) {
  .skills {
    width: 95%;
    height: 90%;
  }
}

.changelog {
  background-color: rgb(60, 60, 60);
  width: 47.5%;
  height: 90%;
  border-radius: 5px;
  box-shadow: 0 0 8px rgb(10, 10, 10);
  overflow: hidden;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
}

.changelog-body {
  font-family: 'Inconsolata', monospace;
  padding: 15px;
  font-size: 20px;
  overflow: scroll;
}

.version {
  margin-top: 20px;
  flex-shrink: 0;
}

.changes {
  margin-left: 20px;
  flex-shrink: 0;
}

@media (max-width: 920px) {
  .changelog {
    width: 95%;
    height: 90%;
  }
}


.contact {
  position: relative;
  width: 47.5%;
  height: 50%;
  background-color: rgb(20, 25, 30);
  box-shadow: 0 0 8px rgb(10, 10, 10);
  border-radius: 5px;
  overflow: hidden;
}

.contact-body {
  width: 100%;
  height: 90%;
  padding: 15px 15px;
  text-align: start;
  font-size: 20px;
  color: rgb(225, 225, 225);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.contact-body a {
  text-decoration: none;
  color: rgb(0, 255, 255);
}

.contact-links {
  display: flex;
}

.github, .linkedin, .angelist {
  fill: white;
}

.github:hover, .linkedin:hover, .angelist:hover {
  fill: #ccc;
}

@media (max-width: 920px) {
  .contact {
    width: 95%;
    height: 90%;
  }
}

