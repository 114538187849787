
.App {
  position: relative;
  width: 90vw;
  height: 100vh;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 920px)  {
  .App {
    width: 100vw;
    height: 100vh;
    
    flex-direction: column;
  }
}

@media (max-width: 360px)  {
  * {
    font-size: 15px;
  }

  .App {
    width: 100vw;
    height: 100vh;
    
    flex-direction: column;
  }
}
