@import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700&display=swap');

.term {
  background-color: rgb(30, 30, 30);
  width: 47.5%;
  height: 90%;
  border-radius: 5px;
  box-shadow: 0 0 8px rgb(10, 10, 10);
  position: relative;
  overflow: hidden;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
}

.term:hover {
  cursor: text;
}

.topbar {
  width: 100%;
  height: 30px;
  background-color: rgb(43, 45, 47);
  padding: 0 10px 0 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.topbar:hover {
  cursor: default;
}

.topbar-buttons {
  width: 65px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.topbar-button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.exit {
  background-color: rgb(238, 101, 90);
}

.minimize {
  background-color: rgb(225, 192, 76);
}

.maximize {
  background-color: rgb(114, 190, 71);
}

.mac-symbols {
  color: rgb(100, 100, 100);
}

.instructions {
  padding: 15px 10px 0 10px;
  font-size: 20px;
  font-family: 'Inconsolata', monospace;

  flex-shrink: 0;
}

.links {
  padding: 10px 10px 10px 10px;
  font-size: 20px;
  font-family: 'Inconsolata', monospace;

  flex-shrink: 0;
}

.link {
  color: rgb(0, 255, 255);
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
}

.cli {
  position: relative;
  padding: 5px 10px 0 10px;
  font-size: 20px;
  font-family: 'Inconsolata', monospace;
  overflow: scroll;
}

.cli-location {
  color: pink;
  font-weight: bold;
}

.cli-input {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: red;
  font-weight: bold;
}

.cli-text {
  background-color: rgb(30, 30, 30);
  font-size: 20px;
  font-family: 'Inconsolata', monospace;
  width: 95%;
  border: 0px;

  outline: 0;
  resize: none;
}

.term-socials {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.github-term, .linkedin-term, .angelist-term {
  margin: 5px;
  height: 25px;
  width: 25px;
  fill: #555;
}

.github-term:hover, .linkedin-term:hover, .angelist-term:hover {
  fill: white;
}

@media (max-width: 920px) {
  .term {
    margin: 10px 0px;
    width: 95%;
    height: 40vh;

    flex-shrink: 0;
  }
}
