
.skills {
  background-color: rgb(20, 30, 20);
  width: 47.5%;
  height: 90%;
  border-radius: 5px;
  box-shadow: 0 0 8px rgb(10, 10, 10);
  overflow: hidden;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
}

.skills-body {
  font-size: 20px;
  padding: 15px 10px;
  overflow: scroll;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

svg {
  height: 80px;
  width: 80px;
  margin: 15px;
}

@media (max-width: 920px) {
  .skills {
    width: 95%;
    height: 90%;
  }
}
