@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:500,700&display=swap');


.projects {
  position: relative;
  width: 47.5%;
  height: 90%;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.project-item {
  border-radius: 5px;
  position: relative;
  background-color: rgb(100, 100, 100);
  box-shadow: 0 0 8px rgb(10, 10, 10);
  width: 95%;
  min-height: 45%;
  margin-top: 5px;
  margin-bottom: 20px;
  overflow: hidden;

  flex-shrink: 0;
}

.project-item:last-child {
  margin-bottom: 5px;
}

.project-item-body {
  padding: 10px 10px;
  font-size: 17px;
  line-height: 25px;
  font-family: 'Source Code Pro', monospace;
}

.project-item-body img {
  width: 100%;
  height: auto;
}

.project-item-table > tbody > tr > td {
  vertical-align: top;
  padding: 5px;
}

.project-item-info a {
  text-decoration: none;
  color: rgb(0, 255, 255);
}

h1 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 920px) {
  .projects {
    width: 95%;
  }

  .project-item-body {
    line-height: 17px;
    font-size: 12px;
  }
}
