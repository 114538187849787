
.contact {
  position: relative;
  width: 47.5%;
  height: 50%;
  background-color: rgb(20, 25, 30);
  box-shadow: 0 0 8px rgb(10, 10, 10);
  border-radius: 5px;
  overflow: hidden;
}

.contact-body {
  width: 100%;
  height: 90%;
  padding: 15px 15px;
  text-align: start;
  font-size: 20px;
  color: rgb(225, 225, 225);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.contact-body a {
  text-decoration: none;
  color: rgb(0, 255, 255);
}

.contact-links {
  display: flex;
}

.github, .linkedin, .angelist {
  fill: white;
}

.github:hover, .linkedin:hover, .angelist:hover {
  fill: #ccc;
}

@media (max-width: 920px) {
  .contact {
    width: 95%;
    height: 90%;
  }
}
